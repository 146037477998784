<template>
    <div class="policies">
      <div class="page__header">
        <div class="page__title">{{ $t(`policies.${"title"}`) }}</div>
        <div class="page__right">

          <form class="search search--filter" @submit.prevent="keywordsSearch()">
            <div class="search__wrapper">
              <input
                class="search__search"
                ref="search"
                :class="{ 'search__search--active': searchKeywords }"
                type="text"
                v-model="searchKeywords"
              />
              <div class="search__icon"></div> 
              <div class="search__splitter"></div>
            </div>
            <div
              class="search__filter"
              ref="filterToggler"
              @click="showSearchFilter = !showSearchFilter"
            ></div>
            <SearchFilter
              v-if="showSearchFilter"
              :searchType="'policy'"
              :filterToggler="$refs.filterToggler"
              :toggleSearchFilter="
                (state) => {
                  showSearchFilter = state;
                }
              "
            />
          </form> 
        </div>
      </div>
      
      <div class="customers__content" >
        <Loading v-if="policiesLoading" /> 
        <div v-else>
          <NoData style="height: 80vh;" :type="'policiesList'" v-if="policiesPerPage.data.length == 0"/>
          <div v-else class="table">
            <div class="table__header box box--default">
              <div class="table__label table__label--small"></div>
              <div class="table__label table__label--large"> 
                {{ $t(`policies.${"tableHead"}.${"insurance"}`) }} 
              </div>
              <div class="table__label table__label--large">
                {{ $t(`policies.${"tableHead"}.${"company"}`) }} 
              </div>
              <div class="table__label table__label--medium">
                {{ $t(`policies.${"tableHead"}.${"status"}`) }} 
              </div>
              <div class="table__label table__label--large">
                {{ $t(`policies.${"tableHead"}.${"customer"}`) }} 
              </div>
              <div class="table__label table__label--medium">
                {{ $t(`policies.${"tableHead"}.${"policyNr"}`) }} 
              </div>
              <div class="table__label table__label--medium">
                {{ $t(`policies.${"tableHead"}.${"created"}`) }} 
              </div>
              <div class="table__label table__label--large">
                {{ $t(`policies.${"tableHead"}.${"agent"}`) }} 
              </div>
              <div class="table__label table__label--small"> 
              </div> 
              <div class="table__label table__label--small"> 
              </div>
              
            </div>

            <div class="table__body">
              <div 
                class="row policy-row row--hover box box--default"
                v-for="policy in policiesPerPage.data"
                :key="policy.id"  
                :class="[
                  `policy--${policy.policy_status.long_en}`, 
                ]"
              >
                <div @click="goToCustomer(policy.customer, policy.customer_id)" class="row__data row__data--small policy-icon" > 
                  <div 
                    :class="[
                      'icon', 
                      `icon__policy--${
                        selectedPolicies[policy.id] 
                          ? policy.policy_services.find(s => s.service.branch_category.long_de === selectedPolicies[policy.id]).service.branch_category.id || 'krankenkasse'
                          : 'krankenkasse'
                      }`
                    ]"
                  >
                  </div>
                </div>    

                <div class="row__data row__data--large" @click="goToCustomer(policy.customer, policy.customer_id)" > 
                  <div @click.stop v-if="policy.policy_services.length > 0" style="width: 100%;  text-align: center;">
                    <VSelect
                      v-if="policy.policy_services.length > 1"
                      style="width: 100%; background: 0;"
                      :options="policy.policy_services.map(service => ({
                        label: service.service.branch_category.long_de,
                        value: service.service.branch_category.long_de
                      }))"
                      label="label"
                      class="select select--default customer__dropdown"
                      v-model="selectedPolicies[policy.id]"
                    />
                    
                    <span v-else >
                      {{ policy.policy_services[0].service.branch_category.long_de }}
                      
                    </span> 
                  </div>
                  <span @click.stop v-else>------</span>
                </div> 

                  
                <div @click="goToCustomer(policy.customer, policy.customer_id)"  class="row__data row__data--name row__data--large">
                  <div @click.stop v-if="policy.company"><p>{{ policy.company.full_name }}</p></div>
                  <div @click.stop v-else>------</div> 
                </div>
                <div @click="goToCustomer(policy.customer, policy.customer_id)"  class="row__data row__data--medium"> 
                  <div @click.stop v-if="policy.policy_status"><p>{{policy.policy_status.name}}</p></div>
                  <div @click.stop v-else>------</div>
                </div>
                <div @click="goToCustomer(policy.customer, policy.customer_id)"  class="row__data row__data--name row__data--large" > 
                  <div @click.stop v-if="policy.customer">
                    <span v-if="policy.customer.company_name">
                      {{policy.customer.company_name}}
                    </span>
                    <span v-else>
                      {{policy.customer.first_name}} {{policy.customer.last_name}}
                    </span> 
                  </div>
                  <div @click.stop v-else>------</div>
                </div>
                <div @click="goToCustomer(policy.customer, policy.customer_id)"  class="row__data row__data--medium" >
                  <div @click.stop v-if="policy.policy_number"><p>{{policy.policy_number}}</p></div>
                  <div @click.stop v-else>------</div> 
                </div>
                <div @click="goToCustomer(policy.customer, policy.customer_id)"  class="row__data row__data--medium">
                  <div @click.stop>
                    <p>{{formatDate(policy.created_at)}}</p>
                  </div> 
                </div>
                <div @click="goToCustomer(policy.customer, policy.customer_id)"  class="row__data row__data--name row__data--large" >
                  <div @click.stop v-if="policy.agent">
                    {{policy.agent.first_name}} {{policy.agent.last_name}}
                  </div>
                  <div @click.stop v-else>------</div> 
                </div> 
                <div class="row__data row__data--small row__data--pdf" @click="openPolicyFilesModal(policy.id, policy.policy_files)">
                  <div class="icon icon__pdf" :class="{ 'icon__pdf--active': policy.policy_files.length }"></div>
                </div>
                <div class="row__data row__data--action row__data--small"></div>   
              </div>
            </div>

            <Pagination
              :limit="3"
              :data="policiesPerPage"
              @pagination-change-page="getResults"
            ></Pagination>
          </div>
        </div>
      </div> 
    </div>
  </template>

  <script>
  import {
    mapGetters
  } from "vuex";
  import SearchFilter from "@/partials/SearchFilter";
  //import RowActionDropdown from "@/components/RowActionDropdown";
  import Loading from "@/partials/Loading";
  import NoData from "@/partials/NoData";
  export default {
    components: {
      SearchFilter,
      NoData,
      Loading, 
      //RowActionDropdown
    },
    data() {
        return{
          showActionsDropdown: false,

            searchKeywords: "",
            showSearchFilter: false,
            selectedAgents: [],
            selectedCompanies: [],
            selectedBranchCategories: [],
            selectedPolicyStatuses: [],
            searchPlaceholder: "",

            selectedPolicies: {}, 

            startDateFrom:"",
            startDateTo: "",

            endDateFrom:"",
            endDateTo: "",

            submitDateFrom:"",
            submitDateTo: "",

        }
    },
    watch: { 
      'policiesPerPage.data': {
        handler(newPolicies) {
          if (!newPolicies || !Array.isArray(newPolicies)) return; 

          newPolicies.forEach(policy => {
            if (!this.selectedPolicies[policy.id] && policy.policy_services.length > 0) {
              this.selectedPolicies[policy.id] = policy.policy_services[0].service.branch_category.long_de;
            }
          });
        },
        deep: true,
        immediate: true
      },

      "$route.query": {
        handler(query) {
          if (!parseInt(this.$route.query.page)) {
            this.$router.replace({
              query: {
                ...this.$route.query,
                page: 1,
              },
            });
          }

          this.searchPlaceholder = query.search;


          this.searchKeywords = query.search || "";
          this.selectedAgents = query.agents
            ? query.agents.split(",").map((id) => parseInt(id))
            : [];

          this.selectedCompanies = query.companies
            ? query.companies.split(",").map((id) => parseInt(id))
            : [];

          this.selectedBranchCategories = query.branchCategories
            ? query.branchCategories.split(",").map((id) => parseInt(id))
            : [];

          this.selectedPolicyStatuses = query.policyStatuses
            ? query.policyStatuses.split(",").map((id) => parseInt(id))
            : [];


          this.startDateFrom = query.startDateFrom || "";
          this.startDateTo = query.startDateTo || "";

          this.endDateFrom = query.endDateFrom || "";
          this.endDateTo = query.endDateTo || "";

          this.submitDateFrom = query.submitDateFrom || "";
          this.submitDateTo = query.submitDateTo || "";
        },
        immediate: true,
        deep: true,
      },
    },
    computed: {
        ...mapGetters([
        "policiesPerPage",
        "policiesLoading", 
        "searchPoliciesForms",
        ]),
    },
    async created() {
 
      this.$store.commit("setPoliciesLoading", true);

      await this.$store.dispatch("getSearchPoliciesForms");

      const { page } = this.$route.query;
      if (!page) {
        this.$router.replace({
          query: {
            page: 1,
          },
        });
      } 

      await this.$store.dispatch("searchPolicies", {
        page: page,
        search: this.searchKeywords,
        agents: this.selectedAgents, 
        companies: this.selectedCompanies,
        branchCategories: this.selectedBranchCategories,
        policyStatuses: this.selectedPolicyStatuses,   

        startDateFrom:this.startDateFrom,
        startDateTo: this.startDateTo,

        endDateFrom:this.endDateFrom,
        endDateTo: this.endDateTo,

        submitDateFrom:this.submitDateFrom,
        submitDateTo: this.submitDateTo, 
      });


      this.$store.commit("setPoliciesLoading", false); 
    },
    methods:{ 
      goToCustomer(policy, id){
        if(policy.customer){
          this.$router.push(`/account-details/customer/${policy.customer.id}`);
        }else{
          this.$router.push(`/account-details/customer/${id}`);
        }
      },
      openPolicyFilesModal(id, files) {
        this.$store.commit("setPolicyFileId", id);
        this.$store.commit("setPolicyFiles", files); 
        this.$store.commit("setShowPolicyFilesModal", true);
      },
 
      formatDate(date) {
        if (!date) return '';
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0');  
        const year = d.getFullYear();
        return `${day}.${month}.${year}`;
      },
 
      async getResults(page) {
      if (parseInt(this.$route.query.page) !== page) {
        this.$store.commit("setPoliciesLoading", true);
        this.$router.replace({
          query: {
            ...this.$route.query,
            page,
          },
        });
        
          await this.$store.dispatch("searchPolicies", {
            page: this.$route.query.page,
            search: this.searchKeywords,
            agents: this.selectedAgents, 
            companies: this.selectedCompanies,
            branchCategories: this.selectedBranchCategories,
            policyStatuses: this.selectedPolicyStatuses,  

            startDateFrom:this.startDateFrom,
            startDateTo: this.startDateTo,

            endDateFrom:this.endDateFrom,
            endDateTo: this.endDateTo,

            submitDateFrom:this.submitDateFrom,
            submitDateTo: this.submitDateTo,
 
          });
        
        this.$store.commit("setPoliciesLoading", false);
      }
      },

      async keywordsSearch() {
            this.$store.commit("setPoliciesLoading", true);
            await this.$store.dispatch("searchPolicies", {
                search: this.searchKeywords,
                agents: this.selectedAgents, 
                companies: this.selectedCompanies,
                branchCategories: this.selectedBranchCategories,
                policyStatuses: this.selectedPolicyStatuses,  
                startDateFrom: this.startDateFrom,
                startDateTo: this.startDateTo,
                endDateFrom: this.endDateFrom,
                endDateTo: this.endDateTo,
                submitDateFrom: this.submitDateFrom,
                submitDateTo: this.submitDateTo, 
            });

            this.$router.replace({
                path: "/policies",
                query: {
                    page: 1,
                    search: this.searchKeywords, 
                },
            });
            this.$store.commit("setPoliciesLoading", false);
      },
    }
  }
  </script>


<style lang="scss" scoped>
.policy-row{
  position: relative; 
  &::before{
    position: absolute;
    z-index: 3;
    content: "";
    left: 0;
    top: -1px;
    height: calc(100% + 2px);
    width: 15px;
    border-top-left-radius: var(--radius-1);
    border-bottom-left-radius: var(--radius-1);
  }
}

.policy--Active{
  &::before{
    background-color: #22DB8E;
  }
} 

.policy--Submitted,
.policy--Processing{
  &::before{
    background-color: #FF9E0F;
  }
}

.policy--Inactive{
  &::before{
    background-color: #DB2222;
  }
}

.page__right{
  display: flex;
  gap:20px;

  .button--default{
    width: 200px;
  }
}
 
</style>